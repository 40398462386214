.modal-content {
  background-color: #1d222a !important;
  //border-color: #828585;
  border: 2px solid #828585 !important;

  .border {
    //border-color: #828585 !important;
  }

}

.modal-bordered {
  //border-radius: 0.3rem;
}
