.tab-menu {
    border-top: 1px solid #868686;
    border-bottom: 1px solid #868686;

    .custom-tab-btn {
        padding: 0.8rem 1.5rem;
        background: linear-gradient(
            to bottom,
            #f0f0f0 0%,
            #e5edf2 77%,
            #cbdce8 89%,
            #c5d4df 100%
        ) !important;
        border: none;
        border-radius: 2rem;
        font-weight: 600;

        &:hover {
            background: #aad6f5 !important;
        }
    }
}
