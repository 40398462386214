@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-gov.min.css");

@font-face {
    font-family: "Pretendard GOV";
    src: url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-gov.min.css") format("woff");
    font-weight: normal;
    font-style: normal;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-family: "Pretendard GOV Variable", "Pretendard GOV", -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    color: white;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    /*overflow: hidden;*/
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

.cursor-hover {
    cursor: pointer;
}

hr {
    margin: 0 !important;
}

#root {
    background-color: #171717;
    height: 100vh;
}

p {
    margin-bottom: 0 !important;
}

:root {
    /* color */
    --background: #131214;
    --on-background: #eae6f2;
    /* --primary: #b5a1e5; */
    --linear-gradient: #d88b30;
    --primary: #d66440;
    --on-primary: #100e17;
    --surface: #1d1c1f;
    --on-surface: #dddae5;
    --on-surface-variant: #b9b6bf;
    --on-surface-variant-2: #7b7980;
    --black-1: #000;
    --black-2: #1d1d1d;
    --gray-1: #2c2c2c;
    --gray-2: #3c3c3c;
    --gray-3: #dedede;
    --white: #fff;
    --outline: #3e3d40;
    --black-opacity-10: rgba(0, 0, 0, 0.1);
    --gray-opacity-2: rgba(222, 222, 222, 0.2);
    --white-opacity-4: rgba(255, 255, 255, 0.04);
    --white-opacity-8: rgba(255, 255, 255, 0.08);
    --white-opacity-12: rgba(255, 255, 255, 0.12);
    --bg-aqi-1: #89e589;
    --bg-aqi-2: #e5dd89;
    --bg-aqi-3: #e5c089;
    --bg-aqi-4: #e58989;
    --bg-aqi-5: #e589b7;
    --on-bg-aqi: #1f331f;

    /* radius */
    --radius-28: 28px;
    --radius-20: 20px;
    --radius-16: 16px;
    --radius-pill: 500px;
    --radius-circle: 50%;

    /* font-size */
    --heading: 5rem;
    --logo: 3rem;
    --logo-text: 1.5rem;
    --title-1: 2.25rem;
    --title-2: 1.75rem;
    --title-3: 1.25rem;
    --title-4: 1rem;
    --label: 0.875rem;
    --weight-semiBold: 600;
    --weight-bold: 700;
}

::-webkit-scrollbar {
    width: 10px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    /* background-color: rgba(255, 255, 255, 0.08); */
    background-color: #8d8d8d;
    border-radius: var(--radius-pill);
    z-index: 3;
}

.fs-7 {
    font-size: 0.875rem;
}

.fs-8 {
    font-size: 0.7rem;
}

button:focus,
input:focus,
button:focus-visible,
input:focus-visible {
    outline: none; /* 기본 하얀색 실선 제거 */
}

.table tr {
    border-color: #828585;
}

.table th {
    background-color: #353c47 !important;
    color: #fff !important;
    height: 45px;
    align-content: center;
    font-size: 1.25rem;
}

.table td {
    background-color: #1d222a !important;
    color: #fff !important;
    height: 60px;
    align-content: center;
    font-size: 1.25rem;
}

.form-check-input {
    margin: 0 0.5rem !important;
}

input[type="radio"] {
    appearance: none; /* 기본(네이티브) 모양을 제거 */
    border-radius: 50%; /* 동그란 모양 */
    box-sizing: border-box;
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid #fff; /* 기본 테두리 색상 */
    background-color: transparent; /* 기본 배경은 투명 */
    cursor: pointer;
    transition: all 0.2s ease-in-out; /* 부드러운 전환 효과 */
}

input[type="radio"]:checked {
    background-color: #3fb5ec; /* 선택된 상태의 내부 배경색 */
    border-color: transparent; /* 선택된 상태의 테두리는 투명 */
}

input[type="checkbox"]:checked {
    background-color: #3fb5ec;
    border-color: #3fb5ec;
}

.page-item.active .page-link {
    background-color: #3fb5ec;
    border-color: #3fb5ec;
}
