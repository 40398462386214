div {
  color: #fff;
}

.landing {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Add this line for horizontal centering */
  margin-top: 100px;
}

.card {
  background-color: #1d222a !important;
  color: #fff !important;
  border: 1px solid #777 !important;
  cursor: pointer;
  min-height: 35vh;
  max-height: 35vh;
  border-radius: 40px !important;

  .card-body {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #fff;

    .card-icon {
      max-width: 150px;
      max-height: 150px;
    }
  }
}
