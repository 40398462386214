body {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

#meeting-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
    /*gap: 10px; !* Optional: Adjust the gap between grid items *!*/
}

#meeting-grid .row > * {
    padding: 0 !important;
}

#meeting-grid .jitsiTrack {
    padding: 0 !important;
    aspect-ratio: 16/9;
}