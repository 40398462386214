input[type="file"]::file-selector-button {
  display: none;
}

input::placeholder {
  color: #000 !important;
}

// .form-grid {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 1rem;
// }

.btn-upload {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  border: 1px solid #868686;
  border-radius: 0.5rem;
  padding: 0.5rem;

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    min-width: 15rem;
    background-color: #3fb5ec;
    border: 1px solid #868686;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;

    img {
      width: 35px;
    }

    span {
      color: #000;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
}

// .pc-btn {
//   display: block;
// }

// .mobile-btn {
//   display: none;
// }

.img-box {
  width: 100%;
  height: 100%;
  min-height: 32rem;
  background-color: #fff;
  border: 1px solid #868686;
  border-radius: 0.5rem;
  padding: 0.7rem 1rem;
}

.custom-box {
  width: 100%;
  height: 100%;
  //   min-height: 100vh;
  color: #000;
  background-color: #fff;
  border: 1px solid #868686;
  border-radius: 0.5rem;
  padding: 0.7rem 1rem;
  font-size: 1.25rem;
}

// .text-area {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 1rem;
// }

// @media (max-width: 768px) {
//   .form-grid {
//     grid-template-columns: repeat(1, 1fr); /* 화면이 좁아지면 1열 */
//   }

//   .text-area {
//     grid-template-columns: repeat(1, 1fr); /* 화면이 좁아지면 1열 */
//     height: 100vh;
//   }

//   .pc-btn {
//     display: none;
//   }

//   .mobile-btn {
//     display: block;
//   }
// }

// @media (min-width: 1024px) {
//   .text-area {
//     grid-template-columns: repeat(2, 1fr); /* 화면이 넓어지면 3열 */
//   }
// }
