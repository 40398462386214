.modal-table td {
  max-width: 7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scenario-btn.active .scenario-content {
  background-color: #3fb5ec;
  color: #000;
  box-shadow: inset 0 0 0 4px #1d222a;
  border: 2px solid #fff !important;
  transition: all 0.3s;
}
