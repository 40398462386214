.datepicker-container {
  background-color: #1d222a;

  .fc-view-harness {
    background-color: white;

    .fc-col-header {
      font-weight: bold;
    }

    .fc-scrollgrid-sync-table {
      text-align: left;

      .fc-daygrid-day-top {
        flex-direction: row;
      }
    }
  }

  a {
    color: black;
    text-decoration: none;
  }
}
