.pagination {

  .page-item {
    .page-link {
      background-color: transparent;
      //border-color: #3FB5EC;
      border-radius: 5px;
      color: white;
    }
  }

  .active{
    .page-link {
      background-color: white !important;
      border-color: white !important;
      color: black;
      font-weight: bold;
    }
  }

  .prev, .next {
    text-align: center;
    //background-color: #3FB5EC;
    .page-link {
      background-color: #3FB5EC;
      border-color: #3FB5EC;
    }
  }

  .disabled {
    .page-link {
      background-color: gray;
      border-color: gray;
    }
  }


}